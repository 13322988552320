export const accordion = {
  backgroundColor: "blue.main",
  // borderRadius: "10rem",
};

export const list = {
  overflow: "auto",
  maxHeight: 300,

  margin: 0,
  padding: 0,
};

export const listItemIcon = {
  "@media (max-width: 62em)": {
    display: "none",
  },
};
