export const forgot = {
  "&:hover": {
    borderBottom: "0.1rem solid",
    borderColor: "red.main",
  },
};

export const form = {
  maxWidth: "35.6rem",
  minWidth: "35.6rem",
};
