export const aboutUs = {
  // backgroundColor: "pink.light",
  borderRadius: "1.6rem",

  // border: "0.2rem solid",
  // borderColor: "pink.main",

  // padding: "2rem",
};

export const title = {
  // backgroundColor: "pink.light",
  padding: "1.6rem",
  borderRadius: "1rem",
};
