export const schedulesBox = {
  // backgroundColor: "blue.light",
  borderRadius: "1.6rem",

  border: "0.2rem solid",
  borderColor: "blue.main",

  // padding: "2rem",
};

export const schedulesTitle = {
  backgroundColor: "blue.main",
  padding: "1.6rem",
  borderRadius: "1rem 1rem 0 0",
};

export const cost = {
  maxHeight: "80rem",
  overflow: "auto",
};
