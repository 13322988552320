export const adviceStyles = {
  backgroundColor: "blue.light",
  p: 2,
  borderRadius: "1.6rem",
  fontStyle: "italic",
};

export const imageList = {
  width: "50rem",
  height: "65rem",
  marginLeft: "auto",
  borderRadius: "5rem 5rem 0 0",
};
