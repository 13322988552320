export const list = {
  overflow: "auto",
  maxHeight: 300,
};

export const listItemIcon = {
  "@media (max-width: 62em)": {
    display: "none",
  },
};
